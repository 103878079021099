"use client";

import { FC, type JSX } from "react";

type ScrollNextButtonProps = {};

export const ScrollNextButton: FC<
  JSX.IntrinsicElements["button"] & ScrollNextButtonProps
> = ({ children, onClick, ...rest }) => {
  return (
    <button
      {...rest}
      type="button"
      onClick={(e) => {
        const scrollContainer = e.currentTarget.closest(".overflow-y-auto");
        if (scrollContainer)
          scrollContainer.scrollBy(0, scrollContainer.scrollHeight);
        if (onClick) onClick(e);
      }}
    >
      {children}
    </button>
  );
};
