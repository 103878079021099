"use client";

import classNames from "classnames";
import { FC, useEffect, useRef, type JSX } from "react";
import { FaArrowsLeftRight, FaHandPointUp } from "react-icons/fa6";

export const Swipe: FC<JSX.IntrinsicElements["div"]> = ({
  children,
  className,
  ...rest
}) => {
  const ref = useRef<HTMLSpanElement | null>(null);

  useEffect(() => {
    const startAnimation = async () => {
      if (ref.current) {
        ref.current.animate(
          [
            { transform: "translateX(-50%)" },
            { transform: "translateX(50%)" },
            { transform: "translateX(-50%)" },
          ],
          {
            duration: 1500,
            delay: 1500,
            iterations: Infinity,
          },
        );
      }
    };
    startAnimation();
  }, []);

  return (
    <div
      tabIndex={-1}
      className={classNames("animate-pulse", className)}
      {...rest}
    >
      <span ref={ref}>
        <FaArrowsLeftRight className="h-4 w-10 translate-y-1" />
        <FaHandPointUp className="h-10 w-10" />
      </span>
      <span className="sr-only">Swipe</span>
    </div>
  );
};
